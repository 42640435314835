























































































































































































































































































































































































































































































































































































































































































































.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
.confirmButtonClass {
  color: #fff !important;
  background-color: #5c6be8 !important;
  border-color: #5c6be8 !important;
}
.operationControlExamination {
  > div {
    justify-content: space-between;
  }
  .el-form {
    display: flex;
    .el-form-item {
      margin-bottom: 0;
    }
    .btns {
      .el-form-item__content {
        margin-left: 0 !important;
      }
    }
  }
  .searchboxs {
    justify-content: flex-start;
  }
}
